import styled from '@emotion/styled'
import React from 'react'
import InViewMonitor from 'react-inview-monitor'

import useLocalisation from '../../helper/useLocalisation'
import Handle from '../../images/carryonnew/handle.svg'
import Laundry from '../../images/carryonnew/laundry.svg'
import Strap from '../../images/carryonnew/strap.svg'
import handle from '../../images/category/handle.svg'
import dream4 from '../../images/dreamscapes/4.svg'
import dream1 from '../../images/giveaway/icon-1.png'
import dream3 from '../../images/giveaway/icon-2.png'
import dream2 from '../../images/giveaway/icon-3.png'

const FadeUp = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeInUp ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

const Fade = ({ children, delay }) => {
  let delayClass = ''
  if (delay !== undefined) {
    delayClass = `delay-c-${delay}s`
  }
  return (
    <InViewMonitor
      classNameNotInView="vis-hidden"
      classNameInView={`animate__animated animate__fadeIn ${delayClass}`}
    >
      {children}
    </InViewMonitor>
  )
}

// Details
const DetailsContainer = styled.div`
  border: solid 1px #cecece;
  padding: 64px 0;
  ${(props) => (props.color ? `background: ${props.color}` : '')}
`

const DetailsHeader = styled.h2`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -1.11px;
  text-align: center;
  color: rgb(var(--dark-blue-grey));
  padding: 32px 0;
`

const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 64px;
  overflow: hidden;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 763px) {
    flex-wrap: wrap;
    height: auto;
    padding: 0 16px;
  }
`

const DetailSectionImage = styled.img`
  width: 70%;
  margin: 0 15%;
`
const DetailSectionImageContainer = styled.div`
  width: 70%;
  margin: 0 15%;
  @media (max-width: 763px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (min-width: 762px) {
    display: flex;
    align-items: flex-end;
    margin-bottom: 16px;
  }
`
const DetailSectionHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.71px;
  text-align: center;
  color: #282828;
  padding: 16px 0;
  min-height: calc(3.25em * 2);
  @media (min-width: 762px) {
    min-height: calc(2.25em * 2);
  }
`
const DetailSectionBody = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.08px;
  text-align: center;
  color: rgb(var(--black));
  padding-bottom: 48px;
`

const DetailSectionContainer = styled.div`
  padding: 0 32px;
  width: 40%;
  @media (max-width: 763px) {
    padding: 0px;
    width: calc(50% - 32px);
  }
`

const ReversableDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 763px) {
    ${(props) =>
      props.reverse
        ? 'flex-direction: column-reverse;'
        : 'flex-direction: column;'}
  }
`

const DetailSection = ({ data }) => {
  return data.map((section, key) => {
    return (
      <DetailSectionContainer key={key}>
        <FadeUp delay={key}>
          <ReversableDiv reverse={key > 1}>
            <DetailSectionImageContainer>
              <DetailSectionImage src={section.image} />
            </DetailSectionImageContainer>
            <DetailSectionHeading> {section.title} </DetailSectionHeading>
          </ReversableDiv>
        </FadeUp>
      </DetailSectionContainer>
    )
  })
}

const Mobile = styled.span`
  @media (min-width: 764px) {
    display: none;
  }
`

export const Heading = styled.h2`
  color: rgb(var(--dark-blue-grey));
  font-size: ${(props) => (props.main ? '28px' : '16px')};
  font-weight: 500;
  line-height: ${(props) => (props.main ? '42px;' : '22px;')};
  margin: 8px 0;
  text-align: center;
  ${(props) =>
    props.scroll
      ? `
    padding-top: 32px;
    @media (min-width: 1025px) {
        padding-top: 124px;
    }
    `
      : ''}
`

const StyledExternalLink = styled.a`
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #282828;
  font-weight: 600;

  z-index: 10;

  &:hover {
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 6px;
    left: 0;
    background-color: rgb(var(--terracota));
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    z-index: 5;
  }

  &:hover::before {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

const DetailsSection = ({ color }) => {
  const Q = useLocalisation()
  return (
    <DetailsContainer color={color}>
      <Heading main={true}>How to enter.</Heading>
      <DetailSectionsContaniner>
        <DetailSection
          data={[
            {
              image: dream1,
              title: (
                <>
                  1. Follow{' '}
                  <StyledExternalLink
                    target="_blank"
                    href="https://instagram.com/july"
                  >
                    @july
                  </StyledExternalLink>{' '}
                  and{' '}
                  <StyledExternalLink
                    target="_blank"
                    href="https://www.instagram.com/fromthememo"
                  >
                    @fromthememo
                  </StyledExternalLink>{' '}
                  on Instagram
                </>
              ),
            },
            {
              title: <>2. Enter your email address below.</>,
              image: dream2,
            },
          ]}
        />
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default DetailsSection
