import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { formatPayload, sendFeedback } from './FeedbackFormAPI'

const EmailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #00000055;
  border-radius: 0px;
  padding: 0 8px;
  margin-bottom: 8px;
`

const EmailInput = styled.input`
  margin: 8px 0;
  line-height: 32px;
  width: 95%;
  border: none;
  font-size: 16px;
  outline-color: transparent;
`

const TextAreaStyled = styled.textarea`
  margin: 8px 0;
  line-height: 32px;
  width: 95%;
  border: none;
  font-size: 16px;
  outline-color: transparent;
  height: 45px;
`

const GreenButton = styled.button`
  background-color: #475f4c;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #475f4c;
  margin: 44px 0;
  width: 100%;
  outline-color: transparent;
`

const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  border: solid 1px rgb(var(--${(props) => props.color}));
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: rgb(var(--${(props) => props.color}));
  background: transparent;
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
    width: calc(100vw - 64px);
    margin-top: 32px;
  }
  @media (min-width: 1025px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;

  &:hover {
    background-color: rgb(var(--dark-blue-grey));
    color: white;
    border-color: transparent;
  }
  &:active {
    background-color: rgb(var(--dark-blue-grey));
    color: ${(props) => (props.text ? `rgb(var(--${props.text}))` : `white`)};
    border-color: transparent;
  }
  outline-color: transparent;
`

const SelectContainer = styled.select`
  width: 100%;
  padding: 16px 8px;
  margin: 8px 0;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
`

const InputLabel = styled.div`
  font-weight: 500;
  line-height: 1.8;
  margin-top: 16px;
  margin-bottom: 4px;
`

const HalfInput = styled.div`
  width: 50%;
  ${(props) => props.padding && 'padding-right: 32px;'}
  @media (max-width: 800px) {
    width: 100%;
    padding: 0;
  }
`

// The SuccessMessage replaces the form if the API succesfully returns
const SuccessMessage = () => (
  <p>
    Thank you for your question. Our happiness team will get back to you within
    a few hours.
  </p>
)

// The ErrorMessage is displayed at the top of the form if API returns error
const ErrorMessage = () => (
  <p className="error">Sorry, we could not process your feedback right now.</p>
)

class FeedbackForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailValue: '',
      emailError: '',
      feedbackValue: '',
      feedbackError: '',
      submitError: false,
      isLoading: false,
      isSubmitted: false,
      confirm: false,
      userName: '',
      country: props.currency === 'au' ? 'Australia' : 'United States',
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.changeCountry = this.changeCountry.bind(this)
    this.confirmClicked = this.confirmClicked.bind(this)
  }

  changeCountry(event) {
    this.setState({ country: event.target.value })
  }

  handleEmailChange(event) {
    this.setState({ emailValue: event.target.value })
  }

  handleNameChange(event) {
    this.setState({ userName: event.target.value })
  }

  handleFeedbackChange(event) {
    this.setState({ feedbackValue: event.target.value })
  }

  confirmClicked(event) {
    this.setState({ confirm: !this.state.confirm })
  }

  async handleSubmit(event) {
    event.preventDefault()

    this.setState({ feedbackError: '', emailError: '', submitError: false })

    // Validate the email field
    // Email regex pattern - if an ivalid email is entered, Zendesk API will return and error
    const regex =
      /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // eslint-disable-line
    if (
      this.state.emailValue.length < 1 ||
      !regex.test(this.state.emailValue)
    ) {
      this.setState({ emailError: 'Please enter a valid email' })
      return
    }

    // Validate the feedback field
    if (this.state.feedbackValue.length === 0) {
      this.setState({
        feedbackError: `You can't send an empty message. Please fill in your answer and hit 'submit'`,
      })
      return
    }

    // If fields are valid, set state to loading and make API POST request
    this.setState({ isLoading: true })
    const payload = formatPayload(
      `${this.props.feedbackSubject} - ${this.state.country}`,
      this.state.emailValue,
      this.state.userName,
      this.state.feedbackValue,
      this.state.confirm
    )
    const response = await sendFeedback(payload, this.props.subdomain)
    this.props.onSub()

    // If we dont get a success response, set submitError to true to show error message
    if (response.status !== 'success') {
      this.setState({ isLoading: false, submitError: true })
      return
    }

    // If we get a success response, set isSubmitted to true to show the success message
    this.setState({ isLoading: false, isSubmitted: true })
  }

  render() {
    if (this.state.isSubmitted) {
      return (
        <div role="alert" aria-live="polite" aria-atomic="true">
          {this.props.children}
        </div>
      )
    } else {
      return (
        <>
          {this.props.title}
          <form onSubmit={this.handleSubmit}>
            <div role="alert" aria-live="polite" aria-atomic="true">
              {this.state.submitError && <ErrorMessage />}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <HalfInput padding={true}>
                <InputLabel> Name </InputLabel>
                <EmailContainer>
                  <EmailInput
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={this.state.userName}
                    onChange={this.handleNameChange}
                  />
                </EmailContainer>
              </HalfInput>
              <HalfInput>
                <InputLabel> Email </InputLabel>
                <EmailContainer>
                  <EmailInput
                    id="email"
                    type="text"
                    name="email"
                    placeholder="example@email.com"
                    value={this.state.emailValue}
                    onChange={this.handleEmailChange}
                  />
                </EmailContainer>
                <p className="error">{this.state.emailError}</p>
              </HalfInput>
            </div>
            <InputLabel>
              {' '}
              Tell us about your dream holiday. Most creative answer wins. (25
              words or less){' '}
            </InputLabel>
            <EmailContainer>
              <TextAreaStyled
                id="feedback"
                name="feedback"
                rows="8"
                placeholder=""
                value={this.state.feedbackValue}
                onChange={this.handleFeedbackChange}
                error={this.state.feedbackError}
              />
            </EmailContainer>
            <p className="error">{this.state.feedbackError}</p>
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <span
                className="label-big-check"
                style={{ padding: 0, paddingLeft: 1 }}
              >
                <input
                  htmlFor="accept-label"
                  aria-label="Accept conditions"
                  name="option_1"
                  id="option_1"
                  style={{ minWidth: 16, color: 'black' }}
                  checked={this.state.confirm}
                  type="checkbox"
                />
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
                <label
                  id="accept-label"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  tabIndex={0}
                  onKeyDown={this.confirmClicked}
                  onClick={this.confirmClicked}
                  htmlFor="option_1"
                  class="check-title"
                  style={{ color: 'black' }}
                >
                  <i className="open far fa-square" />{' '}
                  <i className="closed far fa-check-square" />
                  <span
                    style={{
                      fontSize: 12,
                      lineHeight: '19.2px',
                      marginLeft: 8,
                    }}
                  >
                    {'Accept marketing from July and Luxury Escapes'}
                  </span>
                </label>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div>
                <TransparentButton
                  type="submit"
                  style={{ padding: '0 48px' }}
                  color="dark-blue-grey"
                >
                  {this.state.isLoading ? 'Submitting...' : 'Submit and enter'}
                </TransparentButton>
                <div
                  style={{
                    width: 210,
                    fontSize: 10,
                    padding: '8px 4px',
                  }}
                >
                  {' '}
                  By entering this competition, you agree to the Terms and
                  Conditions.
                </div>
              </div>
            </div>
          </form>
        </>
      )
    }
  }
}

FeedbackForm.defaultProps = {
  feedbackSubject: 'Contact July',
  defaultUserName: 'Anonymous user',
}

FeedbackForm.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  defaultUserName: PropTypes.string,
  currentPageURL: PropTypes.object,
}

export default FeedbackForm
